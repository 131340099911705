<template>
  <b-overlay
    variant="transparent"
    :show="showData"
  >
    <b-tab
      id="mailserver"
      title-item-class="w-25"
    >
      <template #title>
        <b-card
          class="bg-light"
          :img-src="require('@/assets/images/backend/mailSever.png')"
          overlay
        />
      </template>
      <b-form @submit.prevent="updateData">
        <b-row class="infoContent mb-1">
          <b-col>
            <b-form-group
              class="mb-2 mr-1"
              :label="$t('mail.label')"
            >
              <b-form-input
                id="emailHost"
                v-model="emailHost"
                :placeholder="$t('mail.label')"
                autocomplete="new-password"
              />
              <small class="text-primary">{{ $t("mail.small") }}</small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="infoContent mb-1">
          <b-col>
            <b-form-group
              class="mb-2 mr-1"
              :label="$t('mail.port')"
            >
              <b-form-input
                id="emailPort"
                v-model="emailPort"
                :placeholder="$t('mail.port')"

                type="number"
                :min="0"
                autocomplete="new-password"
              />
              <small class="text-primary">{{ $t("mail.port2") }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="infoContent mb-1">
          <b-col>
            <b-form-group
              class="mb-2 mr-1"
              :label="$t('mail.user')"
            >
              <b-form-input
                id="emailUser"
                v-model="emailUser"
                :placeholder="$t('mail.user')"

                autocomplete="new-password"
              />
              <small class="text-primary">{{
                $t("mail.userServ") }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="infoContent mb-1">
          <b-col>
            <b-form-group
              class="mb-2 mr-1"
              :label="$t('mail.pass')"
            >
              <b-input-group>
                <b-form-input
                  id="emailPass"
                  v-model="emailPass"

                  :placeholder="$t('mail.pass')"
                  autocomplete="new-password"
                  :type="passwordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-primary">{{ $t("mail.small2") }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="infoContent mb-1">
          <b-col>
            <b-form-checkbox
              v-model="emailUseTls"
              switch
            >
              {{ $t("mail.tls") }}
            </b-form-checkbox>
            <small class="text-primary"> {{ $t("mail.tls") }}</small>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="text-align: end;">
            <b-button
              type="submit"
              variant="success"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-tab>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BForm,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      emailHost: '',
      emailPort: '',
      emailUser: '',
      onlyStripe: '',
      showData: false,
      id: null,
      emailPass: '',
      emailUseTls: false,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },

  },
  mounted() {
    this.getClient()
  },
  methods: {
    getClient() {
      const userData = getUserData()
      axios
        .post('', {
          query: `query{
            allClients(clientId:"${userData.profile.client.clientId}"){
              edges {
                node {
                  id
                  emailHost
                  emailPort
                  emailUser
                  emailPass
                  emailUseTls
                  sendAdminEmails
                  fromEmail
                  
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]
          this.id = response.node.id
          this.fromEmail = response.node.fromEmail
          this.emailHost = response.node.emailHost
          this.emailPort = response.node.emailPort
          this.emailUser = response.node.emailUser
          this.emailPass = response.node.emailPass
          this.emailUseTls = response.node.emailUseTls
          this.sendAdminEmails = response.node.sendAdminEmails
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateData() {
      this.showData = true
      // eslint-disable-next-line no-restricted-globals
      event.preventDefault()
      axios
        .post('', {
          query: `
            mutation($emailPort: Int){
            updateClient(id:"${this.id}",input:{
                emailHost:"${this.emailHost}",
                emailPort:$emailPort,
                emailUser:"${this.emailUser}",
                emailPass:"${this.emailPass}",              
                emailUseTls:${this.emailUseTls},
            }) {              
              client{
                name
                id
              }
            }
          }
        `,
          variables: { emailPort: this.emailPort ? this.emailPort : null },
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.email'), 1, this)

          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('code.errorEmail'), 2, this)
          this.showData = false
        })
    },
  },
}
</script>
<!--
<style lang="scss" scoped>
.nav-link .card {
    background-color: #f6f6f6 !important;
}

.nav-link.active .card {
    background-image: linear-gradient(47deg, #7367f0, #9e95f5) !important;
}
</style>
 -->
