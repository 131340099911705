<template>
  <section>
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("titleEmail") }}</h1>
        <h5 class="text-primary">
          {{ $t("mail.infoData") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="match-height mb-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showData"
        >
          <b-card>
            <b-card-body class="mt-2 mb-2">
              <validation-observer
                ref="mail"
                tag="form"
                @submit.stop.prevent="validationForm"
              >
                <!-- <b-form @submit.prevent="updateConfigData"> -->
                <b-row class="infoContent mb-1">
                  <b-col>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="'Admin emails'"
                    >
                      <b-form-input
                        id="adminEmails"
                        v-model="adminEmails"
                        :placeholder="$t('mail.admin')"
                        autocomplete="new-password"
                      />
                      <small class="text-primary">{{ $t("mail.admin") }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-1">
                  <b-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="fromEmail"
                      :rules="tabIndex == 1 ? 'required' : ''"
                    >
                      <b-form-group
                        class="mb-2 mr-1"
                        label-for="fromEmail"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                        :label="tabIndex == 1?'From email *':'From email'"
                      >
                        <b-form-input
                          id="fromEmail"
                          v-model="fromEmail"
                          :placeholder="$t('mail.fromEmail')"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          :required="tabIndex == 1"
                        />
                        <small class="text-primary">{{ $t("mail.fromEmail") }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-checkbox
                      v-model="sendAdminEmails"
                      switch
                    >
                      {{ $t("mail.send") }}
                    </b-form-checkbox>
                    <small class="text-primary"> {{ $t("mail.send2") }} </small>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      v-model="sendUserAccountEmails"
                      switch
                    >
                      {{ $t("mail.sendAccount") }}
                    </b-form-checkbox>
                    <small class="text-primary"> {{ $t("mail.send5") }} </small>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      v-model="sendStripePaymentsEmails"
                      switch
                    >
                      {{ $t("mail.sendStripe") }}
                    </b-form-checkbox>
                    <small class="text-primary"> {{ $t("mail.send3") }} </small>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      v-model="sendMobilePaymentsEmails"
                      switch
                    >
                      {{ $t("mail.sendMobile") }}
                    </b-form-checkbox>
                    <small class="text-primary"> {{ $t("mail.send4") }} </small>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="text-align: end;">
                    <b-button
                      type="submit"
                      variant="success"
                    >
                      {{
                        $t("dataGeneric.save")
                      }}
                    </b-button>
                  </b-col>
                </b-row>
                <!-- </b-form> -->
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col>
        <b-card>
          <b-card-body>
            <b-tabs v-model="tabIndex">
              <mail-server />
              <languages-tab-mail />
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
  BTabs,
  BFormCheckbox,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import { showToast, messageError } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

import LanguagesTabMail from './components/LanguagesTabMail.vue'
import MailServer from './components/mail/MailServer.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
    BTabs,
    BButton,
    MailServer,
    LanguagesTabMail,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      tabIndex: 0,
      sendAdminEmails: false,
      sendMobilePaymentsEmails: false,
      sendUserAccountEmails: false,
      sendStripePaymentsEmails: false,
      platform: [],
      fromEmail: '',
      adminEmails: null,
      showData: false,
      userData: getUserData(),
    }
  },
  mounted() {
    if (!this.userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    this.getClient()
  },

  methods: {
    getClient() {
      this.showData = true

      const query = `
        query {
          client(id: "${this.userData.profile.client.id}"){
            id
            bucketType
            adminEmails
            fromEmail
            sendUserAccountEmails
            sendAdminEmails
            sendStripePaymentsEmails
            sendMobilePaymentsEmails

            emailHost
            emailPort
            emailUser
            emailPass

            mailjetApiKey
            mailjetApiSecret  




          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        const response = res.data.data.client
        this.adminEmails = response.adminEmails
        this.fromEmail = response.fromEmail
        this.sendUserAccountEmails = response.sendUserAccountEmails
        this.sendAdminEmails = response.sendAdminEmails
        this.sendStripePaymentsEmails = response.sendStripePaymentsEmails
        this.sendMobilePaymentsEmails = response.sendMobilePaymentsEmails
        this.showData = false

        this.tabIndex = response.mailjetApiKey && response.mailjetApiSecret ? 1 : 0
        this.tabIndex = response.emailHost && response.emailPort && response.emailUser && response.emailPass ? 0 : this.tabIndex
      }).catch(() => {
        this.showData = false
      })
    },
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      return new Promise(resolve => {
        this.$refs.mail.validate().then(success => {
          if (success) {
            resolve(true)
            this.updateConfigData()
          }
        })
      })
    },
    updateConfigData() {
      this.showData = true
      axios
        .post('', {
          query: `
            mutation{
            updateClient(id:"${this.userData.profile.client.id}",input:{
                adminEmails:"${this.adminEmails}",
                fromEmail:"${this.fromEmail}",
                sendUserAccountEmails:${this.sendUserAccountEmails},
                sendAdminEmails:${this.sendAdminEmails},
                sendStripePaymentsEmails:${this.sendStripePaymentsEmails},
                sendMobilePaymentsEmails:${this.sendMobilePaymentsEmails},
            }) {              
              client{
                name
                id
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)
          showToast(this.$t('code.email'), 1, this)
          this.getClient()
          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('code.errorEmail'), 2, this)
          this.showData = false
        })
    },
  },
}
</script>
